import styled from 'styled-components';
import {
  SPACING_LG,
  SPACING_M,
  SPACING_S,
  SPACING_SM,
  SPACING_XS,
} from '../../components/theme/spacing';

export const Wrap = styled.div`
  background-color: #e6e6e6;
`;

export const StyledImg = styled.img`
  margin-top: 12.5rem;
`;

export const EmptyText = styled.span`
  color: #666667;
  margin-top: 2rem;
  width: 24.375rem;
  text-align: center;
`;

export const EmpytTitle = styled.h2`
  margin-top: 3rem;
  font-weight: 700;
`;

export const IconContainer = styled.div`
  min-width: 2.5rem;
  height: 2.5rem;
  background: #f4f5f5;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 0.2rem;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 0.5rem;
`;

export const SellContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const CashierNameContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RefContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  justify-content: flex-start;
  align-items: center;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

export const DataContainer = styled.div`
  display: flex;
  padding: 1rem;
  max-height: auto;
  justify-content: space-between;
`;

export const AmountText = styled.span`
  display: flex;
  text-align: right;
  letter-spacing: 0.005em;
  color: #50b940;
  flex: none;
  flex-grow: 0;
`;

export const ReferenceText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  text-align: right;
  letter-spacing: 0.005em;
  color: #333334;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ButtonText = styled.span<{ textColor?: string }>`
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  color: ${(props) => props.textColor || '#000001'};
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const PrintIconContainer = styled.div`
  display: flex;
  padding: 10px;
  margin-left: 30px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
`;

export const PrintButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin: 1.875rem 1.875rem 1.875rem 0;
  @media (max-width: 700px) {
    margin: 0.875rem 0.875rem 1.875rem 0;
  }
`;

export const PrintButtonText = styled.div`
  color: #003ca5;
  margin-left: 9px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const StyledButton = styled.button<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.7rem;
  max-width: 430px;
  width: 100%;
  height: 80px;
  background: ${(props) => props.backgroundColor || '#ffffff'};
  border: ${({ theme }) => '2px solid' + theme.businessPrimary80};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin: 15px;
`;

export const StyledSeparator = styled.div`
  width: 100%;
  border-top: 1px solid #e6e6e6;
`;

export const MovementsContainer = styled.div`
  display: flex;
  padding: 2rem;
  padding-bottom: 3rem;
  width: 100%;
  height: auto;
  max-height: 65vh;
  margin-bottom: ${SPACING_M};
  background: #ffffff;
  border-radius: 20px;
  flex-direction: column;
  overflow-y: scroll;
`;

export const TableTitleLeft = styled.div`
  display: flex;
  flex: 1;
`;

export const TableTitleRight = styled(TableTitleLeft)`
  flex: 1;
  justify-content: space-around;
`;

export const TextTable = styled.span`
  font-weight: 700;
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const ButtonOption = styled.button<{ optionType: string }>`
  justify-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.7rem;
  width: 390px;
  height: 5rem;
  background: ${(props) => (props.optionType === 'primary' ? '#ffffff' : '#333334')};
  border: ${({ theme }) => '2px solid' + theme.businessPrimary80};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin: 0 1rem;
  @media (max-width: 700px) {
    padding-top: ${SPACING_SM};
    padding-bottom: ${SPACING_SM};
    width: 90%;
    height: 4rem;
  }
  .primary-text {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    color: #000001;
    flex: none;
    order: 0;
    flex-grow: 0;
    @media (max-width: 700px) {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .secondary-text {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    @media (max-width: 700px) {
      font-weight: 700;
      font-size: 16px;
    }
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 0rem 3rem;
  background-color: #e6e6e6;
  @media (max-width: 700px) {
    padding: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const NameText = styled.span`
  font-weight: 700;
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OptionsButtonContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
  @media (max-width: 700px) {
    padding: 1rem;
    height: 11rem;
  }
`;

export const CloseCashierContainer = styled.div`
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  max-width: 38.625rem;
  @media (max-width: 700px) {
    min-width: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .bold-text-container {
    width: 3.75rem;
    margin-right: ${SPACING_XS};
  }
  .bold-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #000001;
    flex: none;
    order: 0;
    flex-grow: 0;
    @media (max-width: 700px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .text-info {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #000001;
    flex: none;
    order: 0;
    flex-grow: 0;
    @media (max-width: 700px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.businessPrimary80};
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: ${SPACING_XS};
    @media (max-width: 700px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.businessPrimary60};
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: ${SPACING_S};
    text-align: center;
    padding-left: ${SPACING_LG};
    padding-right: ${SPACING_LG};
    @media (max-width: 700px) {
      font-size: 14px;
      line-height: 20px;
      padding-left: ${SPACING_SM};
      padding-right: ${SPACING_SM};
    }
  }
`;
export const TitleModalCloseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const DetailsCloseCashierWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => '0.25px solid' + theme.businessPrimary80};
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const TotalCloseWrap = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const DetailsAmount = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.25rem;
`;

export const DetailsCahsier = styled.div`
  display: flex;
  padding-bottom: 0.25rem;
`;

export const CloseCashierButton = styled(ButtonOption)<{ optionType: string }>`
  width: 100%;
  background: ${(props) => (props.optionType === 'primary' ? '#ffffff' : '#333334')};
  margin-left: 0;
  @media (max-width: 700px) {
    height: auto;
  }
  .secondary-text {
    @media (max-width: 700px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const CloseCashierButtonContainer = styled.div`
  margin: 2rem 0rem;
  @media (max-width: 700px) {
    margin-bottom: 0;
  }
`;

export const PrintMovements = styled.div`
  width: 100%;
  justify-content: end;
  display: flex;

  .text-print {
    margin-left: 0.5rem;
  }
`;
export const Component2Print = styled.div`
  z-index: -10000;
  position: absolute;
  right: 0;
  left: 0;
  top: 2.5rem;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem;
`;

export const MobileWrapperMovements = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 3rem;
  width: 85%;
  max-height: 27rem;
  overflow-y: scroll;
  padding: 1rem;
`;

export const ColumnMobile = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ theme }) => '2px solid' + theme.businessPrimary20};
  height: 4rem;
  .column-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .column-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-cashier {
    color: ${({ theme }) => theme.businessPrimary60};
    font-weight: 400;
    font-size: 14px;
  }

  .text-transaction-id {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: ${({ theme }) => theme.businessPrimary40};
  }
`;

export const WrapperByDate = styled.div`
  width: 100%;
  .title-section {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.businessPrimary40};
    margin: 1rem 0rem;
  }
`;

export const IconMobileContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.white};
  border: 0.125rem solid ${({ theme }) => theme.businessPrimary40};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
`;

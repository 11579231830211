import useFetch from '../use-fetch';

const URL = process.env.REACT_APP_API_URL;

export const useCashDeskClosing = (customUserID: string, cashierUserId: string) => {
  const [{ data, error, loading, response, status }, executeCashDeskClosing] = useFetch(
    `${URL}/qr-commerce-ach/v1/qr/close-cashier?cashier-user-id=${cashierUserId}&commerce-user-id=${customUserID}`,
    'POST',
    {
      'Content-Type': 'application/json',
    },
  );
  return {
    data,
    errorCashDeskClosing: error,
    loadingCashDeskClosing: loading,
    response,
    status,
    executeCashDeskClosing,
  };
};

import React from 'react';
import { StyledLoader, StyledLoaderContainer } from './styles';
import { TLoader } from './types';

export const Loader: React.FC<TLoader> = ({ isVisible }) => {
  return isVisible ? (
    <StyledLoaderContainer className="StyledLoaderContainer" open>
      <StyledLoader>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </StyledLoader>
    </StyledLoaderContainer>
  ) : null;
};

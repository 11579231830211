/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useGetCommerceByCardId } from '../services/commerInfo/use-get-commerce-info';
import { useAppContext } from '../store/app-context/app-context';

const useGetCommerceInfo = () => {
  const { setCommerceID } = useAppContext();
  const [loadingCommerceInfo, setLoadingCommerceInfo] = useState(false);
  const { getCommerceInfo, data, error, status } = useGetCommerceByCardId();

  const getCommerceID = async () => {
    setLoadingCommerceInfo(true);
    getCommerceInfo({ timeout: 15000 });
  };

  useEffect(() => {
    if (status === 200 && data) {
      setCommerceID(data.user_id);
      setLoadingCommerceInfo(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      alert('Este usuario no tiene un comercio registrado');
      setLoadingCommerceInfo(false);
    }
  }, [error]);

  return {
    getCommerceID,
    loadingCommerceInfo,
    data,
    error,
  };
};

export default useGetCommerceInfo;

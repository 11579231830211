import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextTable = styled.span<{ paddingLeft?: string; paddingRight?: string }>`
  font-weight: 700;
  padding-left: ${(props) => props.paddingLeft || '0'};
  padding-right: ${(props) => props.paddingRight || '0'};
  margin: 0.313rem;
`;

export const StyledTableCellContainer = styled.div<{ position?: string }>`
  display: flex;
  flex: 1;
  font-weight: 700;
  justify-content: ${(props) => props.position || 'left'};
`;

import styled from 'styled-components';

export const StyledRowButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;
  margin: 0.75rem;
  justify-content: center;
  align-item: center;
  max-width: 24.375rem;

  button:last-child {
    width: 14.688rem;
  }
  @media (max-width: 700px) {
    width: 22rem;
    justify-content: space-around;
    margin: 0rem;
  }
`;

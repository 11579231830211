/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyledMoreSales, ButtonGetMoreSales } from './styles';

interface IMoreSales {
  data: any;
  pageSize: number;
  setPage: (page: number) => void;
  page: number;
}

const MoreSales = ({ data, pageSize, setPage, page }: IMoreSales) => {
  return (
    <StyledMoreSales>
      {data && data.length === pageSize ? (
        <ButtonGetMoreSales
          onClick={() => {
            setPage(page + 1);
          }}>
          Obtener más ventas
        </ButtonGetMoreSales>
      ) : (
        <span>No hay más ventas</span>
      )}
    </StyledMoreSales>
  );
};

export default MoreSales;

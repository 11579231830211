/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';

const numberToDigitList = (value: number): Array<string> =>
  Math.round(value * 100)
    .toString()
    .split('');

const getValue = (digits: Array<string>): number => {
  return Number(digits.join('')) / 100;
};

const useNumericPad = (deleteLabel: string, numericValue: number) => {
  const valueDigits: Array<string> = numberToDigitList(numericValue);
  const [digits, setDigits] = useState<Array<string>>(valueDigits);
  const [value, setValue] = useState<number>(numericValue);

  useEffect(() => {
    const newValue = getValue(digits);
    setValue(newValue);
  }, [digits]);

  const pushDigit = useCallback(
    (digit: string) => {
      const newDigits = [...digits, digit];
      setDigits(newDigits);
    },
    [digits],
  );

  const popDigit = useCallback(() => {
    const newDigits = digits.slice(0, -1);
    setDigits(newDigits);
  }, [digits]);

  const onClickFactory = useCallback(
    (buttonLabel: string) => {
      if (buttonLabel === deleteLabel) {
        return popDigit;
      }

      return () => {
        if (digits.length > 10) return;
        pushDigit(buttonLabel);
      };
    },
    [deleteLabel, popDigit, pushDigit],
  );

  const resetNumericPad = useCallback(() => {
    setValue(0);
    setDigits(['0']);
  }, [setValue, setDigits]);

  return { value, onClickFactory, resetNumericPad };
};

export default useNumericPad;

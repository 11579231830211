import React, { useEffect } from 'react';
import Button from '../button';
import { StyledRowButton } from './styles';
import { IBilletNumericButton } from './types';
import useNumericPad from './useNumericPad';

export const NumericPad: React.FC<IBilletNumericButton> = ({
  numbers,
  deleteLabel,
  onChange,
  numericValue,
  dataTestId,
  ...rest
}) => {
  const { value, onClickFactory, resetNumericPad } = useNumericPad(
    deleteLabel as string,
    numericValue,
  );

  useEffect(() => {
    if (numericValue === 0) resetNumericPad();
  }, [numericValue, resetNumericPad]);

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  const labels = [...numbers, deleteLabel as string];
  return (
    <StyledRowButton data-testid={dataTestId}>
      {labels.map((buttonVal: string) => (
        <Button
          {...rest}
          key={buttonVal}
          label={buttonVal}
          variant="numeric"
          onClick={onClickFactory(buttonVal)}
        />
      ))}
    </StyledRowButton>
  );
};

export const PRIMARY = '#50B940';
export const PRIMARY_80 = '#73C766';
export const PRIMARY_60 = '#96D58C';
export const PRIMARY_40 = '#B9E3B3';
export const PRIMARY_20 = '#DCF1D9';
export const PRIMARY_10 = '#EEF8EC';

export const PRIMARY_COMMERCE = '#1A1A1A';
export const PRIMARY_COMMERCE_80 = '#333334';
export const PRIMARY_COMMERCE_60 = '#666667';
export const PRIMARY_COMMERCE_40 = '#999999';
export const PRIMARY_COMMERCE_20 = '#CCCCCC';
export const PRIMARY_COMMERCE_10 = '#E6E6E6';
export const PRIMARY_COMMERCE_5 = '#F2F2F2';

export const SUCCESS = '#20BF6B';
export const SUCCESS_40 = '#7CE9AF';
export const SUCCESS_10 = '#EDFCF4';
export const ERROR = '#FF2323';

export const WHITE = '#FFFFFF';

export const BILLET_BLACK_60 = '#41474C';
export const BILLET_BLUE = '#003CA5';

import styled from 'styled-components';
import { SPACING_S, SPACING_XS } from '../theme/spacing';

export const StyledButton = styled.button<{ hasError: boolean }>`
  border: none;
  font-weight: 800;
  width: 2.125rem;
  height: 2.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;
export const StyledContainer = styled.div<{ hasError: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid 0.125rem
    ${({ theme, hasError }) => (hasError ? theme.error : theme.businessPrimary10)};
  color: ${({ theme, hasError }) => (hasError ? theme.error : theme.businessPrimary80)};
  border-radius: 0.5rem;
  width: 23.125rem;
  height: 6.5rem;
  margin-top: 0.5rem;
  padding: 1rem;
  @media (max-width: 700px) {
    width: 19rem;
  }
`;
export const StyledText = styled.div<{ hasError: boolean }>`
  margin: ${SPACING_XS};
  padding-top: ${SPACING_S};
  color: ${({ theme, hasError }) => (hasError ? theme.error : theme.businessPrimary)};
  font-weight: 500;
  font-size: 1.5rem;
`;
export const StyledAmount = styled.div<{ hasError: boolean }>`
  margin-bottom: ${SPACING_XS};
  padding-top: ${SPACING_S};
  color: ${({ theme, hasError }) => (hasError ? theme.error : theme.businessPrimary)};
  font-weight: 500;
  font-size: 2.125rem;
`;
export const StyledMessagge = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.error};
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  width: 22.5rem;
  @media (max-width: 700px) {
    width: auto;
  }
`;

import styled from 'styled-components';
import { SPACING_XXXL, SPACING_S, SPACING_XS } from '../../components/theme/spacing';

export const StyledLoginSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-flow: column;
`;

export const StyledLoginFormContainer = styled.div`
  align-items: center;
  display: flex;
  border-radius: 1.25rem;
  flex-direction: column;
  max-width: 32.875rem;
  min-height: auto;
  padding: 3rem ${SPACING_XXXL};
  width: 100%;

  box-shadow: 0rem 0.188rem 0.438rem rgba(0, 0, 0, 0.1);
  background-color: ${({ theme: { white } }) => white};

  .title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 120%;
    margin: 3.5rem 0 1rem 0;
    color: ${({ theme }) => theme.businessPrimary80};
    @media (max-width: 700px) {
      margin: 0rem 0 1rem 0;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 1rem;
    line-height: 120%;
    margin-bottom: 3.688rem;
    color: ${({ theme }) => theme.businessPrimary60};
  }

  @media (max-width: 700px) {
    padding: 3rem 2rem;
    margin: 0rem 2rem;
    width: 90%;
  }
`;

export const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  h5 {
    margin-bottom: ${SPACING_S};
    text-align: center;
  }

  label {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 120%;
    color: ${({ theme }) => theme.businessPrimary60};

    input {
      border: 0;
      width: 100%;
      font-size: 1rem;
      &:focus {
        outline: none;
      }
    }

    .required {
      color: red;
    }
  }

  .login-error {
    color: red;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0.438rem 0 0 0.438rem;
  }

  x {
    gap: ${SPACING_XS};
    margin-bottom: ${SPACING_S};
  }
`;

export const StyledFielContainer = styled.div`
  &:nth-child(2) {
    margin: 2.5rem 0 3.5rem 0;
  }
  font-weight: 700;

  .password-text {
    font-weight: 700;
  }
`;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3.125rem;
  background-color: #333334;
  font-family: 'MulishBold';
  font-size: 1.75rem;
  line-height: 120%;
  letter-spacing: 0.031rem;
  color: white;
  width: 100%;
  min-width: 11.875rem;
  max-width: 24.375rem;
  padding-top: 2.219rem;
  padding-bottom: 2.219rem;

  :disabled {
    background-color: ${({ theme }) => theme.businessPrimary10};
  }

  @media (max-width: 700px) {
    height: 3rem;
    font-size: 16px;
  }
`;

export const StyledInputContainer = styled.div.attrs(
  (props: { error: boolean | undefined }) => props,
)`
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  height: 2.188rem;
  padding: 0 ${SPACING_S};
  border: 0.063rem solid;
  border-color: ${({ theme, error }) => (error ? 'red' : theme.businessPrimary20)};
  margin-top: 0.5rem;
`;

export const StyledBottomMessage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  max-width: 32.875rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 120%;
  margin-top: 1.5rem;
  color: ${({ theme }) => theme.businessPrimary60};
  @media (max-width: 700px) {
    width: 90%;
    .footer-text {
      font-size: 12px;
    }
  }
`;

export const StyledIconContainer = styled.div`
  margin: 0.563rem;
`;

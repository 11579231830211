/* eslint-disable camelcase */
import { IMovement } from '../../types';
import { useEffect } from 'react';
import {
  billetCurrencyFormat,
  getMovementsDateIntervals,
  getMovementsTimeIntervals,
  printDate,
  sumOfMovements,
} from '../../utils';
import {
  PrintMovementContainer,
  DetailsAmount,
  DetailsCashier,
  DetailsPrintMovementWrap,
  TitleModal,
  Footer,
  TransactionsTitleContainer,
  TableHeader,
  StyledTransactionsList,
  StyledSeparator,
} from './styles';

interface IPrintMovements {
  movements: IMovement[];
  cashierName: string;
}

const PrintMovements = ({ movements, cashierName }: IPrintMovements) => {
  useEffect(() => {
    getMovementsDateIntervals(movements);
  }, [movements]);

  getMovementsDateIntervals(movements);
  const headerData = [
    { title: 'Fecha', value: getMovementsDateIntervals(movements) },
    { title: 'Hora', value: getMovementsTimeIntervals(movements) },
    { title: 'Cajero', value: cashierName },
    { title: 'Cant. transacciones', value: `${movements.length}` },
    { title: 'Fecha impresión', value: printDate(new Date().toJSON()) },
  ];

  return (
    <PrintMovementContainer>
      <TitleModal>
        <span className="title">PLAZA LAMA</span>
      </TitleModal>
      <DetailsPrintMovementWrap>
        {headerData.map(({ title, value }: { title: string; value: string }) => (
          <DetailsCashier key={title}>
            <span className="bold-text-header">{title}:&nbsp;</span>
            <span className="text">{value}</span>
          </DetailsCashier>
        ))}
      </DetailsPrintMovementWrap>
      <TransactionsTitleContainer>
        <span className="bold-text">CIERRE DE CAJA</span>
      </TransactionsTitleContainer>
      <TableHeader>
        <span className="bold-text">ID</span>
        <span className="bold-text">MONTO</span>
      </TableHeader>
      {movements.map(({ np_transaction_id, sale_amount }: IMovement) => (
        <StyledTransactionsList key={np_transaction_id}>
          <span>{np_transaction_id}</span>
          <span>{billetCurrencyFormat(sale_amount)}</span>
        </StyledTransactionsList>
      ))}
      <StyledSeparator />
      <DetailsPrintMovementWrap>
        <DetailsAmount>
          <span className="bold-text">TOTAL:</span>
          <span className="bold-text">RD$ {billetCurrencyFormat(sumOfMovements(movements))}</span>
        </DetailsAmount>
      </DetailsPrintMovementWrap>
      <div />
      <Footer>
        <span>Billet.do</span>
      </Footer>
    </PrintMovementContainer>
  );
};

export default PrintMovements;

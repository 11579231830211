import useFetch from '../use-fetch';
import { pageSize } from '../../views/movements/constants';

const URL = process.env.REACT_APP_API_URL;

export const useGetMovements = (commerceID: string, page: number, cashierUserId: string) => {
  const filter = `pageNo=${page}&pageSize=${pageSize}`;

  const [{ data, error, loading, response, status }, getMovements] = useFetch(
    `${URL}/qr-commerce-ach/v1/qr/salesListByCashier?${filter}`,
    'GET',
    {
      'Cashier-User-Id': cashierUserId,
      'Content-Type': 'application/json',
      'Commerce-Id': commerceID,
    },
  );
  return {
    data,
    error,
    loading,
    response,
    status,
    getMovements,
  };
};

import styled from 'styled-components';

export const ActionWrapper = styled.button`
  display: flex;
  width: 44px;
  height: 44px;
  border: 1px solid rgba(195, 212, 233, 0.4);
  border-radius: 90px;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
`;

import styled from 'styled-components';
import { SPACING_M } from '../../components/theme/spacing';

export const StyledNumericPadSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e6e6e6;
`;

export const StyledText = styled.h1`
  margin-top: ${SPACING_M};
  margin-bottom: 0.75rem;
  font-family: MulishRegular;
  font-size: 1.75rem;
  font-style: normal;
`;

export const StyledNumericPadCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  flex-direction: column;
  max-width: 32.875rem;
  min-height: 80%;
  padding: 3rem 3.5rem;
  margin: 5rem 0;
  box-shadow: 0rem 0.188rem 0.438rem rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  @media (max-width: 700px) {
    padding: 1rem 0rem;
    margin: 4rem 2.5rem;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  button {
    min-width: 22.875rem;
    min-height: 6.25rem;
    border-radius: 3.125rem;
    font-size: 1.75rem;
    @media (max-width: 700px) {
      min-height: 3.5rem;
      margin: 1rem;
    }
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledErrorIcon = styled.div`
  position: relative;
  left: -1.5rem;
  font-weight: 800;
`;

import React from 'react';
import { Icon } from '..';
import { TIconName } from '../icon/types';
import {
  StyledModalOverlay,
  CloseSessionWrapper,
  WrapperCloseDialog,
  CloseIconContainer,
} from './styles';
import { IModal } from './types';

const Modal: React.FC<IModal> = ({ children, open, onClose }) => {
  return (
    <StyledModalOverlay open={open} onClose={() => onClose()}>
      <CloseSessionWrapper>
        <WrapperCloseDialog>
          <CloseIconContainer onClick={() => onClose()}>
            <Icon iconName={'error' as TIconName} fill="businessPrimary80" />
          </CloseIconContainer>
        </WrapperCloseDialog>
        {children}
      </CloseSessionWrapper>
    </StyledModalOverlay>
  );
};

export default Modal;

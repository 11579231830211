/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Loader } from '../../../../components';
import ErrorGeneric from '../../../../components/error-generic';
import { ROUTES } from '../../../../routes/constants';
import { useGetDynamicQr } from '../../../../services/dynamicQr/use-get-dynamic-qr';
import { StyledNumericPadCard } from '../../styles';

import {
  AmmountContainer,
  AmmountText,
  BodyText,
  StyledButtonContainer,
  CurrencyText,
  ImgStyled,
  QrGeneratorFooter,
  QrGeneratorHeader,
  Title,
  GoBackContainer,
  GoBackText,
} from './styles';

const QrGenerator = ({
  amount,
  setShowQrGenerator,
}: {
  amount: number;
  setShowQrGenerator: (arg: boolean) => void;
}) => {
  const [dynamicQr, setDynamicQr] = useState('');
  const { getDynamicQr, loading, data, error } = useGetDynamicQr();
  const navigate = useNavigate();

  const thousandsComma = (amount: number) => {
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const navigateToSales = () => {
    navigate(ROUTES.SALES);
  };

  const showQr = async () => {
    getDynamicQr({
      amount,
      fee: 1.55,
    });
  };

  useEffect(() => {
    showQr();
  }, [amount]);

  useEffect(() => {
    if (data) setDynamicQr(String(`data:image/png;base64,${data}`));
  }, [data]);

  if (error) {
    return (
      <ErrorGeneric
        retryFN={() => {
          setShowQrGenerator(false);
        }}
      />
    );
  }

  const dimensionScreen = window.innerWidth;

  return (
    <div>
      <Loader isVisible={loading as boolean} />
      <GoBackContainer onClick={() => setShowQrGenerator(false)} isResponsive>
        <Icon
          iconName={'chevronLeft'}
          width={dimensionScreen < 700 ? '10px' : '20px'}
          height={dimensionScreen < 700 ? '10px' : '20px'}
          fill="blue"
        />
        <GoBackText>Atrás</GoBackText>
      </GoBackContainer>
      <StyledNumericPadCard>
        <QrGeneratorHeader>
          <Title>Monto a pagar</Title>
          <AmmountContainer>
            <CurrencyText>RD$</CurrencyText>
            <AmmountText>{thousandsComma(amount)}</AmmountText>
          </AmmountContainer>
        </QrGeneratorHeader>
        <ImgStyled src={dynamicQr} />
        <QrGeneratorFooter>
          <BodyText>Por favor escanea este QR desde tu App Billet para realizar tu pago</BodyText>
          <StyledButtonContainer>
            <Button variant="business" onClick={() => navigateToSales()} label="Validar pago" />
          </StyledButtonContainer>
        </QrGeneratorFooter>
      </StyledNumericPadCard>
    </div>
  );
};

export { QrGenerator };

/* eslint-disable react-hooks/exhaustive-deps */
import { Login, ChargeQR, Movements } from './views';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { AppContainer, Header } from './components';
import { useAppContext } from './store/app-context/app-context';
import { Auth } from 'aws-amplify';
import { ROUTES } from './routes/constants';

const App: FC = () => {
  const { user, setUser } = useAppContext();
  const location = useLocation();

  const getUserState = async () => {
    const session = await Auth.currentAuthenticatedUser();
    if (session) setUser(session);
  };

  useEffect(() => {
    getUserState();
  }, []);

  if (!user && location.pathname !== ROUTES.LOGIN)
    return <Navigate to={ROUTES.LOGIN} state={{ redirectTo: location.pathname }} />;
  if (user && location.pathname === ROUTES.ROUTE) return <Navigate to={ROUTES.CHARGES} />;

  return (
    <AppContainer className="App">
      <Header />
      <Routes>
        <Route path={ROUTES.LOGIN} element={<Login />} />;
        <Route path={ROUTES.SALES} element={<Movements />} />;
        <Route path={ROUTES.CHARGES} element={<ChargeQR />} />;
      </Routes>
    </AppContainer>
  );
};

export default App;

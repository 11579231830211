import styled from 'styled-components';

export const StyledModalOverlay = styled.dialog`
  z-index: 9999;
  background-color: rgba(0, 0, 1, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const CloseSessionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 1.5rem 1rem;
  min-width: 400px;
  left: calc(50% - 650px / 2);
  top: calc(50% - 258px / 2 - 20.5px);
  background: #ffffff;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  min-height: 15rem;
  @media (max-width: 700px) {
    min-height: auto;
    min-width: auto;
    max-width: 90vw;
  }
`;

export const WrapperCloseDialog = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 13.75px;
  width: 44px;
  height: 44px;
  right: 16px;
  top: 16px;
  background: #f2f2f2;
  border-radius: 275px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  svg {
    width: 33px;
    height: 33px;
  }
  @media (max-width: 700px) {
    width: 24px;
    height: 24px;
    svg {
      width: 13px;
      height: 13px;
    }
  }
`;

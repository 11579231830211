import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useAppContext } from '../../store/app-context/app-context';

const useLogoutUser = () => {
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState(false);
  const { setUser } = useAppContext();

  const logoutUser = async () => {
    setLoading(true);
    try {
      await Auth.signOut();
      setUser(undefined);
      setLoading(false);
    } catch (e: unknown) {
      setError(e);
      setLoading(false);
    }
  };

  return {
    error,
    loading,
    logoutUser,
  };
};

export default useLogoutUser;

import {
  PRIMARY,
  PRIMARY_80,
  PRIMARY_60,
  PRIMARY_40,
  PRIMARY_20,
  PRIMARY_10,
  PRIMARY_COMMERCE,
  PRIMARY_COMMERCE_80,
  PRIMARY_COMMERCE_60,
  PRIMARY_COMMERCE_40,
  PRIMARY_COMMERCE_20,
  PRIMARY_COMMERCE_10,
  PRIMARY_COMMERCE_5,
  SUCCESS,
  SUCCESS_40,
  SUCCESS_10,
  WHITE,
  ERROR,
  BILLET_BLACK_60,
  BILLET_BLUE,
} from '../theme/colors';
import { IBilletTheme } from './types';

export const THEME: IBilletTheme = {
  primary: PRIMARY,
  primary80: PRIMARY_80,
  primary60: PRIMARY_60,
  primary40: PRIMARY_40,
  primary20: PRIMARY_20,
  primary10: PRIMARY_10,
  businessPrimary: PRIMARY_COMMERCE,
  businessPrimary80: PRIMARY_COMMERCE_80,
  businessPrimary60: PRIMARY_COMMERCE_60,
  businessPrimary40: PRIMARY_COMMERCE_40,
  businessPrimary20: PRIMARY_COMMERCE_20,
  businessPrimary10: PRIMARY_COMMERCE_10,
  businessPrimary5: PRIMARY_COMMERCE_5,
  success: SUCCESS,
  success40: SUCCESS_40,
  success10: SUCCESS_10,
  white: WHITE,
  error: ERROR,
  black60: BILLET_BLACK_60,
  blue: BILLET_BLUE,
};

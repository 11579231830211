import React from 'react';
import { StyledContainer, StyledMessagge, StyledButton, StyledText, StyledAmount } from './styles';
import { TIconName } from '../../components/icon/types';
import { IBilletInput } from './types';
import Icon from '../icon';

export const InputAmount: React.FC<IBilletInput> = ({
  amount,
  message,
  hasError = false,
  onClick,
  dataTestId,
}) => {
  const amountQR = amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <>
      <StyledContainer hasError={hasError} data-testid="wrap-input-amount">
        <StyledText hasError={hasError}>RD$</StyledText>
        <StyledAmount hasError={hasError}>{amountQR}</StyledAmount>
        <StyledButton type="button" hasError={hasError} onClick={onClick} data-testid={dataTestId}>
          <Icon iconName={'delete' as TIconName} fill={hasError ? 'error' : 'businessPrimary10'} />
        </StyledButton>
      </StyledContainer>
      <StyledMessagge>{message}</StyledMessagge>
    </>
  );
};

import { useAppContext } from '../../store/app-context/app-context';
import useFetch from '../use-fetch';
const URL = process.env.REACT_APP_API_URL;

export const useGetDynamicQr = () => {
  const {
    commerceID,
    user: { attributes },
  } = useAppContext();
  const [{ data, error, loading }, getDynamicQr] = useFetch(
    `${URL}/commerce/v1/commerces/${commerceID}/qr/${attributes['sub']}/payments/base64`,
    'POST',
    {
      Accept: 'text/plain',
      'Content-Type': 'application/json',
    },
  );
  return { data, error, loading, getDynamicQr };
};

import styled, { css } from 'styled-components';
import { SPACING_S, SPACING_XS } from '../theme/spacing';

export const StyledButton = styled.button<{ variant: string; disabled: boolean }>`
  border: none;
  border-radius: 16px;
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  padding: ${SPACING_XS} ${SPACING_S};
  background: ${({ theme, variant }) => {
    switch (variant) {
      case 'primary':
        return theme.primary;
      case 'business':
        return theme.businessPrimary;
      case 'numeric':
        return theme.businessPrimary5;
      default:
        return theme.primary;
    }
  }};
  :disabled {
    background-color: ${({ theme, variant }) => {
      switch (variant) {
        case 'business':
          return theme.businessPrimary10;
        default:
          return theme.primary;
      }
    }};
  }
  ${({ variant }) =>
    variant === 'numeric' &&
    css`
      min-width: 6.9rem;
      min-height: 6.2rem;
      border-radius: 0.5rem;
      color: #000001;
      font-weight: 800;
      font-size: 2.125rem;
      margin: 0.5rem;
      padding: 2.125rem 2.75rem;
      background-color: #f2f2f2;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    `}

  @media (max-width: 700px) {
    height: 3.55rem;
    padding: 0rem;
    min-width: 90.42px;
    min-height: 60px;
    font-size: 1.7rem;
  }
`;

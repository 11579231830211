import styled from 'styled-components';

export const LateralMenuContainer = styled.div`
  box-sizing: border-box;
  width: 286px;
  height: 900px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border-right: 1px solid #f3f5f7;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledTitle = styled.h1`
  width: auto;
  height: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgba(148, 167, 203, 0.4);
  flex: none;
  margin-bottom: 42px;
  margin-top: 36px;
`;

export const StyledTitleLogOut = styled.button`
  border: none;
  background-color: transparent;
  width: auto;
  height: 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #90a3bf;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 12px;
`;

export const StyledSectionTitle = styled(StyledTitleLogOut)<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? 'white' : '#90a3bf')};
`;

export const SectionContainer = styled.div<{ isSelected: boolean }>`
  align-items: center;
  background: ${(props) => (props.isSelected ? '#50B940;' : 'white')};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 56px;
  left: 16px;
  padding: 0 12px;
  top: 200px;
  width: 100%;
`;
export const LogOutSection = styled.section`
  cursor: pointer;
  display: flex;
`;

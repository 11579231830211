import styled from 'styled-components';
import { SPACING_LG, SPACING_MD, SPACING_SM } from '../theme/spacing';

const w = window.innerWidth;

export const StyledDiv = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  width: ${w}px;
`;

export const StyledHeader = styled.nav<{ isUserActive: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 4rem;
  justify-content: center;
  width: 100vw;
  @media (max-width: 700px) {
    display: ${(props) => (props.isUserActive ? 'none' : 'flex')};
  }
`;

export const StyledBilletLogo = styled.img`
  height: 30px;
  width: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  background: #333334;
  width: 100%;
`;

export const StyledButton = styled.div<{ isSelected: boolean }>`
  align-items: center;
  background: ${(props) => (props.isSelected ? '#50B940' : '#333334')};
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 4px 40px;
  background: ${(props) => (props.isSelected ? '#50B940' : '#333334')};
  @media (max-width: 700px) {
    padding: 4px 20px;
  }
`;

export const StyledButtonText = styled.span`
  height: 38px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 0.625rem;
  @media (max-width: 700px) {
    font-size: 18px;
  }
`;

export const StyledText = styled.span`
  height: 38px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 0.625rem;
  @media (max-width: 700px) {
    font-size: 18px;
  }
`;

export const ChildrenContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
`;

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100vw;
`;

export const CloseSessionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: -4rem;
  padding-right: 0.5rem;
  width: 100vw;
`;

export const Text = styled.span`
  width: 102px;
  height: 24px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #333334;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 0.5rem;
`;

export const TextModalSession = styled.span`
  width: auto;
  height: auto;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: ${SPACING_LG};
  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 24px;
    padding-left: ${SPACING_SM};
    padding-right: ${SPACING_SM};
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.438rem 0rem;
  width: 100%;
  background: ${({ theme }) => theme.businessPrimary80};
  border: ${({ theme }) => '2px solid' + theme.businessPrimary80};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin-bottom: ${SPACING_MD};
  @media (max-width: 700px) {
    padding-top: ${SPACING_SM};
    padding-bottom: ${SPACING_SM};
    margin-bottom: 0;
  }
`;

export const ButtonText = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  @media (max-width: 700px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const MobileButton = styled.button`
  display: none;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 0px 8px 0px 20px;
  width: 5rem;
  background: ${({ theme }) => theme.white};
  flex: none;
  order: 0;
  flex-grow: 0;
  @media (max-width: 700px) {
    display: flex;
  }
`;

export const HeaderResponsive = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  @media (max-width: 500px) {
    width: auto;
  }
`;

export const DialogMobileSession = styled.div`
  display: flex;
  position: absolute;
  top: 4rem;
  background-color: white;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0rem;
  z-index: 30;
  .wrap-user {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
    width: 100%;
  }

  .text-wrap-user {
    margin-left: 1rem;
  }

  .button-text-mobile-session {
    width: 89px;
    height: 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.005em;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;

export const CloseSessionMobileButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px 16px 20px;
  gap: 8px;
  width: 160px;
  height: 32px;
  background: #333334;
  border-radius: 30px;
`;

export const ModalChild = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  min-width: 38.625rem;
  @media (max-width: 700px) {
    min-width: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

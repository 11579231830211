/* eslint-disable camelcase */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { THEME } from './components/theme-provider/billet-theme';
import { ThemeProvider } from 'styled-components';
import { AppContextProvider } from './store/app-context/app-context';
import { Amplify } from 'aws-amplify';
import { COGNITO_CONFIG } from './services/login/const';
import { BrowserRouter } from 'react-router-dom';

Amplify.configure({
  aws_cognito_region: COGNITO_CONFIG.REGION,
  aws_user_pools_id: COGNITO_CONFIG.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO_CONFIG.APP_CLIENT_ID,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AppContextProvider>
    <BrowserRouter>
      <ThemeProvider theme={THEME}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </AppContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

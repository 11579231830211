import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background: #f4f5f5;
  border-radius: 33px;
  flex: none;
  flex-grow: 0;
  margin-right: 19px;
`;

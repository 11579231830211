import useFetch from '../use-fetch';
const URL = process.env.REACT_APP_API_URL;

export const useGetCommerceByCardId = () => {
  const [{ data, loading, error, status }, getCommerceInfo] = useFetch(
    `${URL}/commerce/v1/commerces/`,
    'GET',
    {
      'Content-Type': 'application/json',
    },
  );
  return { data, error, loading, status, getCommerceInfo };
};

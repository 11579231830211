import { FC, useState } from 'react';
import { Button, InputAmount, NumericPad, Icon } from '../../components';
import { TIconName } from '../../components/icon/types';
import { NUMBERS_BUTTONS } from './constants';
import { getError, getErrorMessage } from './useValidateAmount';
import {
  StyledNumericPadCard,
  StyledButtonContainer,
  StyledNumericPadSection,
  StyledText,
  StyledIconContainer,
  StyledErrorIcon,
} from './styles';
import QrGenerator from './partials/qrGenerator';

export const ChargeQR: FC = () => {
  const [amountValue, setAmountValue] = useState<number>(0);
  const [showQrGenerator, setShowQrGenerator] = useState(false);

  const getBackspaceIcon = () => {
    return (
      <StyledIconContainer>
        <Icon iconName={'backspace' as TIconName} width="42" height="32" />
        <StyledErrorIcon>
          <Icon iconName={'error' as TIconName} width="16" height="22" fill="white" />
        </StyledErrorIcon>
      </StyledIconContainer>
    );
  };

  return (
    <StyledNumericPadSection>
      {showQrGenerator ? (
        <QrGenerator amount={amountValue} setShowQrGenerator={setShowQrGenerator} />
      ) : (
        <StyledNumericPadCard>
          <StyledText data-testid="txt-title">Monto a cobrar con QR</StyledText>
          <InputAmount
            amount={amountValue}
            message={getErrorMessage(amountValue)}
            hasError={getError(amountValue)}
            onClick={() => setAmountValue(0)}
            dataTestId="inp-amount"
          />
          <NumericPad
            onChange={(newValue) => setAmountValue(newValue)}
            numbers={NUMBERS_BUTTONS}
            numericValue={amountValue}
            deleteLabel={getBackspaceIcon()}
            dataTestId="btn-numeric-pad"
          />
          <StyledButtonContainer>
            <Button
              type="button"
              disabled={amountValue === 0 || getError(amountValue)}
              label="Generar QR"
              variant="business"
              onClick={() => {
                if (amountValue > 0) setShowQrGenerator(true);
              }}
            />
          </StyledButtonContainer>
        </StyledNumericPadCard>
      )}
    </StyledNumericPadSection>
  );
};

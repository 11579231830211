import styled from 'styled-components';
import { SPACING_M, SPACING_S } from '../theme/spacing';

export const StyledErrorCard = styled.div`
  align-items: center;
  display: flex;
  border-radius: 1.25rem;
  flex-direction: column;
  width: 37rem;
  min-width: 11.25rem;
  padding: 2.938rem 4.5rem;
  box-shadow: 0rem 0.188rem 0.438rem rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-top: 5rem;
  margin-bottom: 5rem;
  @media (max-width: 700px) {
    max-width: 21rem;
    padding: 1.5rem 1rem;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledMainContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const StyledErrorTitle = styled.p`
  font-family: 'MulishBold';
  font-size: 1.75rem;
  line-height: 2.1rem;
  letter-spacing: 0.031rem;
  margin-top: 0rem;
  margin-bottom: 1rem;
  color: #333334;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }
`;

export const StyledErrorText = styled.p`
  font-family: 'MulishRegular';
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.031rem;
  text-align: center;
  color: #666667;
  margin-bottom: 3.938rem;
  margin-top: 0rem;
  @media (max-width: 700px) {
    font-size: 0.875rem;
    margin-bottom: 3.375rem;
  }
`;

export const StyledErrorImage = styled.img`
  max-width: 100%;
  height: auto;
  @media (max-width: 700px) {
    max-width: 60%;
  }
`;

export const StyledErrorButton = styled.button`
  border: none;
  border-radius: 3.125rem;
  background-color: #333334;
  font-family: 'MulishBold';
  font-size: 1.75rem;
  line-height: 120%;
  letter-spacing: 0.031rem;
  color: white;
  width: 27rem;
  margin-top: 3.938rem;
  height: 5rem;
  :active {
    background-color: #000001;
  }
  @media (max-width: 840px) {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: 700px) {
    font-size: 1rem;
    height: 3.5rem;
    margin-top: 3.375rem;
  }
`;

export const StyledGoBackContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${SPACING_M};
`;

export const StyledGoBackText = styled.p`
  color: ${({ theme }) => theme.blue};
  font-family: 'MulishBold';
  font-size: 0.875rem;
  letter-spacing: 0.005em;
  line-height: 1.25rem;
  margin: 0;
  padding-left: ${SPACING_S};
`;

export const StyledErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

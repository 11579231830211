import { MESSAGES, AMOUNT } from './constants';

export const getErrorMessage = (amountValue: number) => {
  if (amountValue < AMOUNT.MIN_LIMIT && amountValue > 0) return MESSAGES.MIN_LIMIT;
  if (amountValue > AMOUNT.MAX_LIMIT) return MESSAGES.MAX_LIMIT;
  return '';
};

export const getError = (amountValue: number) => {
  if (amountValue < AMOUNT.MIN_LIMIT && amountValue > 0) return true;
  if (amountValue > AMOUNT.MAX_LIMIT) return true;
  return false;
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { groupBy } from 'lodash';

import { EmptySearch } from '../../assets/images';
import Icon from '../../components/icon';
import { TIconName } from '../../components/icon/types';
import ErrorGeneric from '../../components/error-generic';
import ModalCloseCashier from './partials/modal-close-cashier';
import ModalPrintMovement from './partials/modal-print-movement/modal-print-movement';
import {
  CashierNameContainer,
  AmountContainer,
  PrintIconContainer,
  PrintButtonContainer,
  PrintButtonText,
  StyledSeparator,
  EmptyContainer,
  StyledImg,
  EmptyText,
  EmpytTitle,
  MovementsContainer,
  IconContainer,
  NameContainer,
  SellContainer,
  RefContainer,
  ButtonOption,
  Container,
  DataContainer,
  AmountText,
  ReferenceText,
  NameText,
  ErrorContainer,
  OptionsButtonContainer,
  Component2Print,
  Wrap,
  MobileWrapperMovements,
  ColumnMobile,
  WrapperByDate,
  IconMobileContainer,
} from './styles';
import { pageSize } from './constants';
import { useGetMovements } from '../../services/movements/use-get-movements';
import TableHeader from './partials/table-header/table-header';
import { getPmAmHour, formatDateMobile, billetCurrencyFormat, updateUtcTimestamp } from './utils';
import Modal from '../../components/modal';
import { useReactToPrint } from 'react-to-print';
import { IMovement, IMovementMobile } from './types';
import ModalConfirmClose from './partials/modal-confirm-close/modal-confirm-close';
import MoreSales from './partials/more-sales/more-sales';
import PrintMovements from './partials/print-all-movements/print-all-movements';
import { Loader } from '../../components';
import { useGetAllMovements } from '../../services/movements/use-get-all-movements';
import { useAppContext } from '../../store/app-context/app-context';
import { useCashDeskClosing } from '../../services/movements/use-cash-desk-closing';

const PAGE_SIZE_ALL_MOVEMENTS = 1000;

const Movements: React.FC = () => {
  const [page, setPage] = useState(0);
  const [movements, setMovements] = useState<IMovement[]>([]);
  const [movementsToPrint, setMovementsToPrint] = useState<IMovement[]>([]);
  const [openSessionDialog, setOpenSessionDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IMovement | null>(null);
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false);
  const [secondModal, setSecondModal] = useState<boolean>(false);
  const [printingMovementsInCourse, setPrintingMovementsInCourse] = useState<boolean>(false);
  const [movementsMobile, setMovementsMobile] = useState<IMovementMobile[]>([]);

  const isMobileDimension = window.innerWidth < 750;

  const componentRefAllMovements = useRef<HTMLInputElement>(null);
  const componentRefMovement = useRef<HTMLInputElement>(null);
  const {
    user: {
      attributes: { name, family_name },
      attributes,
    },
    commerceID,
  } = useAppContext();
  const cashierUserId: string = attributes['sub'] || '';

  const cashierFullName = `${name} ${family_name || ''}`;
  const openPrintAllMovementsScreen = useReactToPrint({
    content: () => componentRefAllMovements.current,
    onAfterPrint: () => setPrintingMovementsInCourse(false),
  });

  const handlePrintMovement = useReactToPrint({
    content: () => componentRefMovement.current,
    onAfterPrint: () => setSelectedItem(null),
  });

  const handleCloseModal = () => {
    setOpenSessionDialog(false);
    setSecondModal(true);
  };

  const { data, getMovements, error, loading } = useGetMovements(
    commerceID || '',
    page,
    cashierUserId,
  );
  const { allMovements, alMovementsError, allMovementsLoading, getAllMovements } =
    useGetAllMovements(0, commerceID || '', cashierUserId, PAGE_SIZE_ALL_MOVEMENTS);

  const { executeCashDeskClosing, loadingCashDeskClosing, errorCashDeskClosing } =
    useCashDeskClosing(commerceID || '', cashierUserId);

  useEffect(() => {
    getMovements();
  }, [page, commerceID]);

  useEffect(() => {
    let addMovements = [];
    if (data && data.length > 0 && !isMobileDimension) {
      addMovements = [...movements, ...data];
      setMovements(addMovements);
    } else if (data && data.length > 0 && isMobileDimension) {
      const getDateFormat = (movement: IMovement) => movement.timestamp.substring(0, 10);
      const groupedData = groupBy(data, getDateFormat);
      const keys = Object.keys(groupedData);
      const sectionListData = Object.values(groupedData).map((data, index) => ({
        title: keys[index],
        data,
      }));
      setMovementsMobile(sectionListData);
    }
  }, [data]);

  useEffect(() => {
    if (selectedItem) {
      handlePrintMovement();
      setTimeout(() => {
        setLoadingPrint(false);
      }, 800);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (allMovements && allMovements.length) {
      setMovementsToPrint(allMovements);
    }
  }, [allMovements]);

  useEffect(() => {
    if (printingMovementsInCourse) {
      openPrintAllMovementsScreen();
    }
  }, [movementsToPrint]);

  if (error || alMovementsError || errorCashDeskClosing) {
    return (
      <ErrorContainer>
        <ErrorGeneric
          retryFN={() => {
            setPage(1);
          }}
        />
      </ErrorContainer>
    );
  }

  const handlePrintAllMovements = () => {
    getAllMovements();
    setPrintingMovementsInCourse(true);
  };

  const handleOpenSesionDialog = () => {
    getAllMovements();
    setOpenSessionDialog(true);
  };

  const handleCloseSesionDialog = () => {
    setOpenSessionDialog(false);
    setMovementsToPrint([]);
  };

  const handleCashDeskClosingAction = async () => {
    await executeCashDeskClosing();
    setSecondModal(false);
    await setMovements([]);
    await setMovementsMobile([]);
  };

  return (
    <Wrap>
      <Loader isVisible={!!loading || !!allMovementsLoading || !!loadingCashDeskClosing} />
      <Component2Print ref={componentRefAllMovements}>
        {printingMovementsInCourse && movementsToPrint.length > 0 && (
          <PrintMovements movements={movementsToPrint} cashierName={cashierFullName} />
        )}
      </Component2Print>
      <Component2Print ref={componentRefMovement}>
        {selectedItem && (
          <ModalPrintMovement
            close={() => {
              setOpenSessionDialog(false);
              setSelectedItem(null);
            }}
            movement={selectedItem}
            cashierFullName={cashierFullName}
          />
        )}
      </Component2Print>
      {(movements.length > 0 || movementsMobile.length > 0) && (
        <Container>
          <PrintButtonContainer onClick={handlePrintAllMovements}>
            <Icon fill="blue" iconName="printer" />
            <PrintButtonText>Imprimir cierre</PrintButtonText>
          </PrintButtonContainer>
          {isMobileDimension ? (
            <MobileWrapperMovements>
              {movementsMobile.map((item, index) => {
                return (
                  <WrapperByDate key={index}>
                    <span className="title-section">{formatDateMobile(item.title)}</span>
                    {item.data.map((mov: IMovement, index: number) => {
                      return (
                        <ColumnMobile>
                          <div className="column-left" key={index}>
                            <span className="text-cashier">{mov.client_user_id}</span>
                            <span className="text-transaction-id">{mov.np_transaction_id}</span>
                          </div>
                          <div className="column-right">
                            <AmountText>+ RD$ {billetCurrencyFormat(mov.sale_amount)}</AmountText>
                            <IconMobileContainer
                              onClick={() => {
                                if (!loadingPrint) {
                                  setLoadingPrint(true);
                                  setSelectedItem(mov);
                                }
                              }}>
                              <Icon iconName={'ticket' as TIconName} />
                            </IconMobileContainer>
                          </div>
                        </ColumnMobile>
                      );
                    })}
                  </WrapperByDate>
                );
              })}
            </MobileWrapperMovements>
          ) : (
            <MovementsContainer>
              <TableHeader />
              <StyledSeparator />
              <>
                {movements.map((item: IMovement) => {
                  return (
                    <DataContainer key={item.id}>
                      <SellContainer className="SellContainer">
                        <IconContainer>
                          <Icon iconName={'moneyDown' as TIconName} fill="primary" />
                        </IconContainer>
                        <NameContainer>
                          <NameText>{item.client_user_id} </NameText>
                          <span>{`${getPmAmHour(updateUtcTimestamp(item.timestamp))}`}</span>
                        </NameContainer>
                      </SellContainer>
                      <CashierNameContainer>
                        <ReferenceText>{cashierFullName}</ReferenceText>
                      </CashierNameContainer>
                      <RefContainer>
                        <ReferenceText>{item.np_transaction_id}</ReferenceText>
                      </RefContainer>
                      <AmountContainer>
                        <AmountText>RD$ {billetCurrencyFormat(item.sale_amount)}</AmountText>
                        <PrintIconContainer>
                          <Icon
                            fill={`${loadingPrint ? 'businessPrimary40' : 'blue'}`}
                            iconName="printer"
                            onClick={() => {
                              if (!loadingPrint) {
                                setLoadingPrint(true);
                                setSelectedItem(item);
                              }
                            }}
                          />
                        </PrintIconContainer>
                      </AmountContainer>
                    </DataContainer>
                  );
                })}
              </>
              {!loading && (
                <MoreSales data={data} pageSize={pageSize} setPage={setPage} page={page} />
              )}
            </MovementsContainer>
          )}
          <OptionsButtonContainer>
            <ButtonOption
              optionType="primary"
              onClick={async () => {
                setMovements([]);
                setPage(0);
                getMovements();
              }}>
              <span className="primary-text">Refrescar ventas</span>
            </ButtonOption>
            <ButtonOption optionType="secondary" onClick={handleOpenSesionDialog}>
              <span className="secondary-text">Cierre de caja</span>
            </ButtonOption>
          </OptionsButtonContainer>
        </Container>
      )}

      {!loading && movements.length === 0 && movementsMobile.length === 0 && (
        <EmptyContainer>
          <StyledImg src={EmptySearch} alt="Logo Billet" />
          <EmpytTitle>¡Aún no tienes movimientos!</EmpytTitle>
          <EmptyText>
            Comienza a usar tus Superpoderes y disfruta de todos nuestros beneficios
          </EmptyText>
        </EmptyContainer>
      )}
      {openSessionDialog && !allMovementsLoading && (
        <Modal open={openSessionDialog} onClose={handleCloseSesionDialog}>
          <ModalCloseCashier
            close={handleCloseModal}
            movements={movementsToPrint}
            cashierName={cashierFullName}
          />
        </Modal>
      )}
      {secondModal && (
        <Modal open={secondModal} onClose={() => setSecondModal(false)}>
          <ModalConfirmClose
            close={handleCashDeskClosingAction}
            printAll={handlePrintAllMovements}
          />
        </Modal>
      )}
    </Wrap>
  );
};

export default Movements;

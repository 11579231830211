/* eslint-disable camelcase */
import { Auth } from 'aws-amplify';
import { useState } from 'react';

import { TLoginError } from './types';

export const useSignIn = () => {
  const [loading, setIsLoading] = useState(false);
  const [error, setOnSignInError] = useState<TLoginError | null>(null);

  const cleanSignInErrors = () => setOnSignInError(null);

  const signIn = async (documentNumber: string, password: string) => {
    setIsLoading(true);

    try {
      const user = await Auth.signIn(documentNumber, password);
      setIsLoading(false);
      if (user) {
        return user;
      }
      return null;
    } catch (err: unknown) {
      setOnSignInError(err as TLoginError);
      setIsLoading(false);
    }
  };

  return {
    error,
    loading,
    signIn,
    cleanSignInErrors,
  };
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
};

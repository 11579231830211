import styled from 'styled-components';

export const PrintMovementContainer = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 0.005em;
  line-height: 120%;
  flex: none;
  color: #000001;

  .bold-text {
    font-weight: 700;
  }

  .bold-text-header {
    font-weight: 700;
    font-size: 0.9rem;
  }

  .title {
    font-size: 1.75rem;
    font-weight: 700;
  }

  .text {
    font-weight: 400;
    font-size: 0.9rem;
  }
`;

export const TitleModal = styled.div`
  margin-bottom: 1.375rem;
`;

export const DetailsPrintMovementWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.5rem 0;
`;

export const DetailsAmount = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
`;

export const DetailsCashier = styled.div`
  display: flex;
  padding-top: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const TransactionsTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  border-bottom: 0.016rem solid ${({ theme }) => theme.businessPrimary80};
  padding-bottom: 0.8rem;
`;

export const TableHeader = styled.div`
  display: flex;
  border-bottom: 0.016rem solid ${({ theme }) => theme.businessPrimary80};
  padding-bottom: 0.8rem;
  justify-content: space-between;
`;

export const StyledTransactionsList = styled.div`
  display: flex;
  padding: 0.5rem 0;
  justify-content: space-between;
`;

export const StyledSeparator = styled.div`
  border-bottom: 0.016rem solid ${({ theme }) => theme.businessPrimary80};
  margin-top: 0.5rem;
`;

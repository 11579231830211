import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Loader } from '../../components';
import Icon from '../../components/icon';
import { useSignIn } from '../../services/login/use-login';
import { useAppContext } from '../../store/app-context/app-context';
import {
  StyledBottomMessage,
  StyledButton,
  StyledFielContainer,
  StyledIconContainer,
  StyledInputContainer,
  StyledLoginForm,
  StyledLoginFormContainer,
  StyledLoginSection,
} from './styles';
import { IFormValues } from './types';
import useGetCommerceInfo from '../../hooks/useGetCommerceInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/constants';

interface CustomizedState {
  redirectTo: string | null;
}

export const Login: FC = () => {
  const { setUser, user } = useAppContext();
  const { getCommerceID, loadingCommerceInfo, data: commerceID } = useGetCommerceInfo();
  const { signIn, loading, error, cleanSignInErrors } = useSignIn();
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const { register, handleSubmit, watch, reset, clearErrors } = useForm<IFormValues>();
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const onLoginSubmit = async ({ username, password }: IFormValues) => {
    const user = await signIn(username, password);

    if (user) {
      setUser(user);
    }
  };

  useEffect(() => {
    if (user) getCommerceID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const state = currentLocation.state as CustomizedState;
    const route2Redirect = state.redirectTo !== null ? state.redirectTo : ROUTES.CHARGES;

    if (commerceID) navigate(route2Redirect, { replace: false });
  }, [commerceID, currentLocation.state, navigate]);

  useEffect(() => {
    if (error) console.log({ ...error });
  }, [error]);

  const formData = watch(['username', 'password']);
  const validatedInputData = () => formData[0]?.length > 0 && formData[1]?.length > 5;
  const showError = () => error && error.name === 'NotAuthorizedException';
  const credentialsError = 'Usuario o contraseña incorrectos';
  const genericError = 'Ha ocurrido un error';
  const ErrorMessage = () => (
    <span className="login-error">{showError() ? credentialsError : genericError}</span>
  );

  const resetForm = () => {
    reset();
    clearErrors();
    cleanSignInErrors();
  };

  return (
    <StyledLoginSection>
      <Loader isVisible={loading || loadingCommerceInfo} />
      <StyledLoginFormContainer>
        <div className="title">Inicia sesión</div>
        <div className="subtitle">Cobro con QR</div>
        <StyledLoginForm onSubmit={handleSubmit(onLoginSubmit)}>
          <label>
            <StyledFielContainer>Usuario</StyledFielContainer>
            <StyledInputContainer error={!!error}>
              <Icon iconName="user" fill="black60" width="18" height="20" />
              <input {...register('username', { required: true, minLength: 1 })} />
            </StyledInputContainer>
            {error && <ErrorMessage />}
          </label>
          <StyledFielContainer>
            <label className="password-text">
              Contraseña
              <StyledInputContainer error={!!error}>
                <Icon iconName="lock" fill="black60" width="18" height="20" />
                <input
                  {...register('password', { required: true, minLength: 2 })}
                  type={hiddenPassword ? 'password' : 'text'}
                />
                <Icon
                  iconName={error ? 'delete' : hiddenPassword ? 'eyeOn' : 'eyeOff'}
                  fill="black60"
                  width="18"
                  height="20"
                  onClick={() => (error ? resetForm() : setHiddenPassword(!hiddenPassword))}
                />
              </StyledInputContainer>
              {error && <ErrorMessage />}
            </label>
          </StyledFielContainer>
          <StyledButton
            disabled={!validatedInputData()}
            type="button"
            onClick={handleSubmit(onLoginSubmit)}>
            Iniciar sesión
          </StyledButton>
        </StyledLoginForm>
      </StyledLoginFormContainer>
      <StyledBottomMessage>
        <StyledIconContainer>
          <Icon iconName="info" fill="businessPrimary60" width="22" height="22" />
        </StyledIconContainer>
        <span className="footer-text">
          Si tienes problemas para iniciar sesión ponte en contacto con el supervisor/encargado en
          turno
        </span>
      </StyledBottomMessage>
    </StyledLoginSection>
  );
};

import React from 'react';
import { IconProps } from './types';
import { iconsSystem, iconsCategory } from './svg';
import { THEME } from '../theme-provider/billet-theme';

const colors = {
  ...THEME,
};

const iconList = {
  ...iconsSystem,
  ...iconsCategory,
};

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const {
    iconName,
    width = '24',
    height = '24',
    fill = 'businessPrimary',
    testid,
    onClick,
  } = props;
  const fillValue = colors[fill as keyof typeof colors] || 'none';

  return iconList[iconName]({ width, height, testid, fill: fillValue, onClick: onClick });
};

export default Icon;

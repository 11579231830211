import styled from 'styled-components';

export const QrGeneratorHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;
export const QrGeneratorFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  width: 100%;
`;
export const AmmountContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0.5rem;
`;
export const GoBackContainer = styled.div<{ isResponsive?: boolean }>`
  width: 32.875rem;
  display: flex;
  padding-left: 0.6rem;
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0rem 0rem 1rem;

  @media (max-width: 700px) {
    display: ${(props) => (props.isResponsive ? 'flex' : 'none')};
    color: #003ca5;
    padding: 1rem 0rem 0rem 3rem;
  }
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  button {
    min-width: 22.875rem;
    min-height: 6.25rem;
    border-radius: 3.125rem;
    font-size: 1.75rem;
    @media (max-width: 700px) {
      min-width: 304px;
      min-height: 56px;
    }
  }
`;
export const ImgStyled = styled.img`
  height: 374px;
  margin: 3rem 0;
  width: 374px;
  @media (max-width: 700px) {
    width: 248px;
    height: 248px;
  }
`;
export const Title = styled.h1`
  font-family: MulishRegular;
  font-size: 1.75rem;
  font-style: normal;
  @media (max-width: 700px) {
    font-weight: 700;
    font-size: 20px;
  }
`;
export const CurrencyText = styled.p`
  font-family: MulishRegular;
  font-size: 2rem;
  font-style: normal;
  font-weight: bolder;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 38.4px;
  @media (max-width: 700px) {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
  }
`;
export const AmmountText = styled.p`
  font-family: MulishRegular;
  font-size: 4.063rem;
  font-style: normal;
  font-weight: bolder;
  margin: 0;
  @media (max-width: 700px) {
    font-weight: 800;
    font-size: 34px;
  }
`;
export const GoBackText = styled.p`
  font-family: MulishRegular;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: bolder;
  margin: 0;
  color: #003ca5;
  padding: 0rem 1rem;
  @media (max-width: 700px) {
    font-weight: 700;
    font-size: 14px;
    margin-left: 0.3rem;
    padding: 0rem;
  }
`;
export const BodyText = styled.p`
  font-family: MulishRegular;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 14px;
    font-weight: 400;
    margin: 1rem;
  }
`;

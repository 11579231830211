import styled from 'styled-components';

export const StyledMoreSales = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonGetMoreSales = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #003ca5;
  font-weight: bold;
`;

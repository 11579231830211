/* eslint-disable camelcase */
import React from 'react';
import { IMovement } from '../../types';
import { billetCurrencyFormat, formatDdMmYyDate, updateUtcTimestamp } from '../../utils';
import {
  PrintMovementContainer,
  DetailsAmount,
  DetailsCashier,
  DetailsPrintMovementWrap,
  TitleModal,
  Footer,
  TransactionIDLabel,
} from './styles';

interface IModalCashier {
  close: () => void;
  movement: IMovement;
  cashierFullName: string;
}

const ModalPrintMovement = ({ movement, cashierFullName }: IModalCashier) => {
  const { timestamp, sale_amount, client_user_id, np_transaction_id } = movement;
  const movementDate = new Date(updateUtcTimestamp(timestamp));

  return (
    <PrintMovementContainer>
      <TitleModal>
        <span className="title">PLAZA LAMA</span>
      </TitleModal>
      <DetailsPrintMovementWrap>
        <DetailsCashier>
          <span className="bold-text">Fecha:&nbsp;</span>
          <span>{formatDdMmYyDate(updateUtcTimestamp(timestamp))}</span>
        </DetailsCashier>
        <DetailsCashier>
          <span className="bold-text">Hora:&nbsp;</span>
          <span>{movementDate.toLocaleTimeString().toLocaleLowerCase()}</span>
        </DetailsCashier>
        <DetailsCashier>
          <span className="bold-text">Cajero:&nbsp;</span>
          <span>{cashierFullName}</span>
        </DetailsCashier>
        <DetailsCashier>
          <span className="bold-text">Cliente:&nbsp;</span>
          <span>{client_user_id}</span>
        </DetailsCashier>
        <DetailsCashier>
          <span className="bold-text">ID Transacción:&nbsp;</span>
          <TransactionIDLabel>{np_transaction_id}</TransactionIDLabel>
        </DetailsCashier>
      </DetailsPrintMovementWrap>
      <DetailsPrintMovementWrap>
        <DetailsAmount>
          <span className="bold-text">Monto Total:</span>
          <span>RD$ {billetCurrencyFormat(sale_amount)}</span>
        </DetailsAmount>
      </DetailsPrintMovementWrap>
      <div />
      <Footer>
        <span>Billet.do</span>
      </Footer>
    </PrintMovementContainer>
  );
};

export default ModalPrintMovement;

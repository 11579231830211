import styled from 'styled-components';
import { ButtonOption } from '../../styles';

export const PrintMovementContainer = styled.div`
  .bold-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #000001;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #000001;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #000001;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;

export const TitleModal = styled.div`
  margin-bottom: 24px;
`;

export const DetailsPrintMovementWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #333334;
  justify-content: space-around;
  padding: 0.5rem 0 0.5rem 0;
`;

export const DetailsAmount = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
`;

export const DetailsCashier = styled.div`
  display: flex;
  padding-top: 1rem;
  align-items: center;
`;

export const CloseCashierButton = styled(ButtonOption)<{ optionType: string }>`
  width: 100%;
  background: ${(props) => (props.optionType === 'primary' ? '#ffffff' : '#333334')};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const TransactionIDLabel = styled.span`
  font-weight: 600;
  font-size: 20px;
`;

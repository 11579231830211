import { IMovement } from './types';

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

/**
 * Format a timestamp to the local hour in format HH:MM am (6:43 pm)
 * @param timestamp date to format in format 2023-01-02T18:43:20.375812
 * @returns local hour as 6:43 pm format
 */
export const formatAmPmHour = (timestamp: string) => {
  const time = new Date(timestamp);
  const hours = time.toLocaleTimeString('en-US');
  const secondsIndex = hours.lastIndexOf(':');
  return `${hours.substring(0, secondsIndex)} ${hours.split(' ')[1].toLocaleLowerCase()}`;
};

/**
 * Given a timestamp, builds the date written in a common format.
 * @param timestamp date in format 2023-01-02T18:43:20.375812
 * @returns the given date formated to: 6:43 pm, 1 de Enero, 2023
 */
export const getPmAmHour = (timestamp: string) => {
  const time = new Date(timestamp);
  const monthFromDate = monthNames[time.getMonth()];
  const day = time.toLocaleDateString('en-GB', { day: 'numeric' });
  const year = time.getFullYear();

  return `${formatAmPmHour(timestamp)}, ${day} de ${monthFromDate}, ${year}`;
};

export const thousandSeparator = (amount: number) => {
  const strAmount = amount.toString().split('.');
  const wholePart = parseInt(strAmount[0]).toLocaleString();
  return `${wholePart}.${strAmount[1] || '00'}`;
};

/**
 * Format the given amount to the Billet currency format: RD$ 25.00
 * @param amount number to conver. e.g. 25
 * @returns number un currency format like 25.00
 */
export const billetCurrencyFormat = (amount: number) => {
  const strAmount = amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return strAmount;
};

/**
 * Format the given a timestamp.
 * @param timestamp date in format 2023-01-02T18:43:20.375812
 * @returns date in format DD/MM/YY
 */
export const formatDdMmYyDate = (timestamp: string) => {
  const movementDate = new Date(timestamp);
  const stringDate = movementDate.toLocaleDateString('en-GB');

  return `${stringDate.substring(0, stringDate.length - 4)}${movementDate
    .getFullYear()
    .toString()
    .substring(2, 4)}`;
};

/**
 * Format two given timestamps to: "Desde 10/02/23 hasta 11/02/23"
 * @param timestampFrom initial date in format 2023-01-02T18:43:20.375812
 * @param timestampTo final date in format 2023-01-02T18:43:20.375812
 * @returns The formated interval
 */
export const fromToDate = (timestampFrom: string, timestampTo: string) => {
  return `Desde ${formatDdMmYyDate(timestampFrom)} hasta ${formatDdMmYyDate(timestampTo)}`;
};

/**
 * Format two given timestamps to: "Desde 7:43 am hasta 5:33 am"
 * @param timestampFrom initial date in format 2023-01-02T18:43:20.375812
 * @param timestampTo final date in format 2023-01-02T18:43:20.375812
 * @returns The formated interval
 */
export const fromToHour = (timestampFrom: string, timestampTo: string) => {
  return `Desde ${formatAmPmHour(timestampFrom)} hasta ${formatAmPmHour(timestampTo)}`;
};

/**
 * Format the given timestamp to 11/02/23 a las 7:34 am
 * @param timestamp string in format like 2023-01-02T18:43:20.375812
 * @returns formated timestamp as 11/02/23 a las 7:34 am
 */
export const printDate = (timestamp: string) =>
  `${formatDdMmYyDate(timestamp)} a las ${formatAmPmHour(timestamp)}`;

/**
 * Given a list of movements, sums all their sale values
 * @param movements list of movememts to sum
 * @returns sum of sale_amount
 */
export const sumOfMovements = (movements: IMovement[]): number =>
  movements.reduce((previousValue, currentValue) => previousValue + currentValue.sale_amount, 0);

export const getMovementsDateIntervals = (movements: IMovement[]) => {
  const oldestMovementTimestamp = updateUtcTimestamp(movements[movements.length - 1].timestamp);
  const newestMovementTimestamp = updateUtcTimestamp(movements[0].timestamp);

  return fromToDate(oldestMovementTimestamp, newestMovementTimestamp);
};

export const getMovementsTimeIntervals = (movements: IMovement[]) => {
  const oldestMovementTimestamp = updateUtcTimestamp(movements[movements.length - 1].timestamp);
  const newestMovementTimestamp = updateUtcTimestamp(movements[0].timestamp);

  return fromToHour(oldestMovementTimestamp, newestMovementTimestamp);
};

/**
 * Given a date with format YYYY/MM/DD return 'day of month' for mobile table title section
 * @param timestamp list of movememts to sum
 * @returns 'day of month' format date
 */
export const formatDateMobile = (timestamp: string): string => {
  const time = new Date(timestamp);
  const monthFromDate = monthNames[time.getMonth()];
  const date = timestamp.split('-');
  return `${date[2]} de ${monthFromDate}`;
};

/**
 * Convert a UTC timestamp to another readable UTC timestamp to allow to display the local time.
 * @param timestamp in format 2023-02-08T21:08:48.446597
 * @returns timestamp in format 2023-02-08T21:08:48.000Z
 */
export const updateUtcTimestamp = (timestamp: string) => {
  const utcDate = new Date(timestamp);
  const localTime = new Date(`${utcDate} UTC`).toLocaleString('en-US');

  return new Date(localTime).toJSON();
};

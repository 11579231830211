/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { HmacSHA256 } from 'crypto-js';
import { TFetchMethod, TFetchResponse } from './types';
import { useAppContext } from '../store/app-context/app-context';

const idempotencyKey = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

const parseResponse = (response: Response) => {
  const contentType = response.headers.get('content-type');
  switch (contentType) {
    case 'application/json':
      return response.json();
    case 'text/plain;charset=UTF-8':
    case 'text/html':
      return response.text();
    default:
      return response.json();
  }
};

const useFetch = (
  path: string,
  method: TFetchMethod,
  headers = {},
): [TFetchResponse, (params?: any) => any] => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<number | null>(null);
  const [response, setResponse] = useState<Response | null>(null);
  const [data, setData] = useState<any | null>(null);
  const [error, setError] = useState<any | null>(null);
  const { user } = useAppContext();

  const getUserHeaders = async (body: { [key: string]: string }) => {
    const baseHeaders: { [key: string]: string } = headers ? headers : {};

    if (user) {
      baseHeaders['X-Idempotency-Key'] = idempotencyKey();
      baseHeaders['x-version'] = idempotencyKey();
      baseHeaders['cardId'] = user.attributes['custom:card_id'];
      baseHeaders['userId'] = user.attributes['custom:user_id'];
      baseHeaders['Authorization'] = `Bearer ${user.signInUserSession.idToken.jwtToken}`;
      baseHeaders['AccessToken'] = user.signInUserSession.accessToken.jwtToken;

      const queryUrl = path.toString().split('?');
      let xSignatureToken;

      if (body) {
        xSignatureToken = HmacSHA256(
          JSON.stringify(body),
          process.env.REACT_APP_GW_API_REQ || '',
        ).toString();
        baseHeaders['x-signature'] = xSignatureToken;
        return baseHeaders;
      }

      if (!body && queryUrl && queryUrl?.length > 0) {
        xSignatureToken = HmacSHA256(
          queryUrl[1],
          process.env.REACT_APP_GW_API_REQ || '',
        ).toString();
        baseHeaders['x-signature'] = xSignatureToken;
      }
    }

    return baseHeaders;
  };

  const callFetch = async (body: { [key: string]: string } = {}) => {
    // Cleaning previus data, if any.
    setStatus(null);
    setResponse(null);
    setData(null);
    setError(null);
    // Starting fetch
    setLoading(true);
    let requestHeaders;
    if (user) requestHeaders = await getUserHeaders(body);

    fetch(path, {
      method,
      headers: requestHeaders,
      body: method === 'GET' ? null : JSON.stringify(body),
    })
      .then(async (result) => {
        setResponse(result);
        setStatus(result.status);

        // Handling success
        if (result.status >= 200 && result.status < 300) {
          const data = await parseResponse(result);
          setData(data);
          setLoading(false);
          return;
        }

        // Handling Error
        if (result.status >= 300) {
          const parsedError = await result.json();
          setError(parsedError);
          setLoading(false);
        }
      })
      .catch((e) => {
        // Handling non-response related errors
        console.error(e);
        setError(e);
        setLoading(false);
      });
  };
  return [
    {
      loading,
      status,
      response,
      data,
      error,
    },
    callFetch,
  ];
};

export default useFetch;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const MovementContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
  width: 486px;
  height: 73px;

  background: #ffffff;

  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f5;
  }
`;

export const DescriptionSection = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section`
  display: flex;
`;
export const TrasactionsContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: white;
`;

export const HomeContainer = styled.div`
  background-color: #f6f7f9;
`;

export const TransactionText = styled.text<{ transactionType: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => (props.transactionType === 'CASHOUT' ? '#5E676E' : '#20BF6B')};
`;

export const TransactionTypeTitle = styled.h1`
  width: 192px;
  height: 24px;
  left: 27px;
  top: 24px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;

  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  margin-bottom: 50px;
  color: #1a202c;
`;

export const DateTitle = styled.h1`
  width: 446px;
  height: 24px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
  color: #41474c;
`;

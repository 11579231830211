export const SPACING_XXS = '4px';
export const SPACING_XS = '8px';
export const SPACING_S = '12px';
export const SPACING_SM = '16px';
export const SPACING_M = '20px';
export const SPACING_MD = '24px';
export const SPACING_LG = '32px';
export const SPACING_XL = '48px';
export const SPACING_XXL = '56px';
export const SPACING_XXXL = '68px';

import useFetch from '../use-fetch';

const URL = process.env.REACT_APP_API_URL;

export const useGetAllMovements = (
  page: number,
  commerceID: string,
  cashierUserId: string,
  customPageSize?: number,
) => {
  const filter = `pageNo=${page}&pageSize=${customPageSize}`;

  const [{ data, error, loading, response, status }, getAllMovements] = useFetch(
    `${URL}/qr-commerce-ach/v1/qr/salesListByCashier?${filter}`,
    'GET',
    {
      'Cashier-User-Id': cashierUserId,
      'Content-Type': 'application/json',
      'Commerce-Id': commerceID,
    },
  );
  return {
    allMovements: data,
    alMovementsError: error,
    allMovementsLoading: loading,
    allMovementsResponse: response,
    allMovementsStatus: status,
    getAllMovements,
  };
};

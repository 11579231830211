import React from 'react';
import { StyledButton } from './styles';
import { IBilletButton } from './types';

export const Button: React.FC<IBilletButton> = ({
  variant = 'primary',
  label,
  onClick,
  disabled = false,
  ...rest
}) => {
  return (
    <StyledButton
      data-testid="wrap-button"
      type="button"
      variant={variant}
      onClick={onClick}
      {...rest}
      disabled={disabled}>
      {label}
    </StyledButton>
  );
};

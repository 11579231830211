import React from 'react';
import { StyledTableCellContainer, TextTable, TitleContainer } from './styles';

const TableHeader: React.FC = () => {
  return (
    <TitleContainer>
      <StyledTableCellContainer>
        <TextTable paddingLeft="1.25rem">Ventas QR</TextTable>
      </StyledTableCellContainer>
      <StyledTableCellContainer>
        <TextTable>Nombre Cajero</TextTable>
      </StyledTableCellContainer>
      <StyledTableCellContainer>
        <TextTable>Referencia</TextTable>
      </StyledTableCellContainer>
      <StyledTableCellContainer position="right">
        <TextTable paddingRight="5.625rem">Monto recibido</TextTable>
      </StyledTableCellContainer>
    </TitleContainer>
  );
};

export default TableHeader;

import styled from 'styled-components';
import { SPACING_LG, SPACING_SM } from '../../../../components/theme/spacing';

export const StyledContainer = styled.div`
  padding-left: 3.25rem;
  padding-right: 3.25rem;
  max-width: 618px;
  text-align: center;
  @media (max-width: 700px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.businessPrimary80};
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: ${SPACING_LG};
    text-align: center;
    @media (max-width: 700px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: ${SPACING_SM};
      padding-left: ${SPACING_SM};
      padding-right: ${SPACING_SM};
    }
  }
  .subtitle {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.businessPrimary60};
    padding-left: ${SPACING_LG};
    padding-right: ${SPACING_LG};
    @media (max-width: 700px) {
      font-size: 14px;
      line-height: 20px;
      padding-left: ${SPACING_SM};
      padding-right: ${SPACING_SM};
    }
  }
`;

export const ButtonOption = styled.button<{ optionType: string }>`
  justify-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.7rem;
  width: 390px;
  height: 5rem;
  color: ${({ theme }) => theme.businessPrimary80};
  border: ${({ theme }) => '2px solid' + theme.businessPrimary80};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin: 0 1rem;
  @media (max-width: 700px) {
    padding-top: ${SPACING_SM};
    padding-bottom: ${SPACING_SM};
  }
  .secondary-text {
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    @media (max-width: 700px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const TitleModalCloseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const CloseCashierButtonContainer = styled.div`
  margin: 2rem 0rem;
  @media (max-width: 700px) {
    margin: 1rem 0rem;
  }
`;

export const CloseCashierButton = styled(ButtonOption)`
  width: 100%;
  background: ${({ theme }) => theme.businessPrimary80};
  margin-left: 0;
  @media (max-width: 700px) {
    height: auto;
  }
`;

export const PrintButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
  @media (max-width: 700px) {
    margin-bottom: 0rem;
  }
`;

export const PrintButtonText = styled.div`
  color: ${({ theme }) => theme.blue};
  margin-left: 9px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
`;

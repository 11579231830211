import React from 'react';
import {
  CloseCashierButton,
  CloseCashierButtonContainer,
  CloseCashierContainer,
  DetailsAmount,
  DetailsCahsier,
  DetailsCloseCashierWrap,
  TitleModalCloseWrapper,
  TotalCloseWrap,
} from '../styles';
import { IMovement } from '../types';
import { billetCurrencyFormat, formatDdMmYyDate, sumOfMovements } from '../utils';

interface IModalCashier {
  close: () => void;
  movements: IMovement[];
  cashierName: string;
}

const ModalCloseCashier: React.FC<IModalCashier> = ({ close, movements, cashierName }) => {
  const currentDate = new Date();
  const cashDeskClosingData = [
    { label: 'Fecha:', value: formatDdMmYyDate(currentDate.toJSON()) },
    { label: 'Hora:', value: currentDate.toLocaleTimeString().toLocaleLowerCase() },
    { label: 'Cajero:', value: cashierName },
  ];

  return (
    <CloseCashierContainer>
      <TitleModalCloseWrapper>
        <span className="title">Cierre de caja</span>
        <span className="text">
          Al realizar el cierre de caja, todas las transacciones actuales desaparecerán.
        </span>
        <span className="text">Cierre actual</span>
      </TitleModalCloseWrapper>
      <DetailsCloseCashierWrap>
        {cashDeskClosingData.map(({ label, value }: { label: string; value: string }) => (
          <DetailsCahsier key={label}>
            <span className="bold-text">{label}&nbsp;</span>
            <span className="text-info">{value}</span>
          </DetailsCahsier>
        ))}
      </DetailsCloseCashierWrap>
      <TotalCloseWrap>
        <DetailsAmount>
          <span className="bold-text">Monto total: </span>
          <span className="text-info">RD$ {billetCurrencyFormat(sumOfMovements(movements))}</span>
        </DetailsAmount>
        <DetailsAmount>
          <span className="bold-text">Cant. de transacciones: </span>
          <span className="text-info">{movements.length}</span>
        </DetailsAmount>
      </TotalCloseWrap>
      <>
        <CloseCashierButtonContainer>
          <CloseCashierButton optionType="secondary" onClick={() => close()}>
            <span className="secondary-text">Cerrar turno</span>
          </CloseCashierButton>
        </CloseCashierButtonContainer>
      </>
    </CloseCashierContainer>
  );
};

export default ModalCloseCashier;

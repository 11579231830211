import React from 'react';
import Icon from '../../../../components/icon';
import {
  StyledContainer,
  TitleModalCloseWrapper,
  CloseCashierButtonContainer,
  CloseCashierButton,
  PrintButtonContainer,
  PrintButtonText,
} from './styles';
import { IModalConfirmClose } from './types';

const ModalConfirmClose: React.FC<IModalConfirmClose> = ({ close, printAll }) => {
  return (
    <StyledContainer>
      <TitleModalCloseWrapper>
        <span className="title">¿Estás seguro de cerrar el turno?</span>
        <span className="subtitle">
          Al realizar el cierre de caja, todas las transacciones actuales desaparecerán y no podrás
          imprimir el cierre más adelante.
        </span>
      </TitleModalCloseWrapper>
      <CloseCashierButtonContainer>
        <CloseCashierButton optionType="secondary" onClick={() => close()}>
          <span className="secondary-text">Cerrar turno</span>
        </CloseCashierButton>
      </CloseCashierButtonContainer>
      <PrintButtonContainer onClick={() => printAll()}>
        <Icon fill="blue" iconName="printer" />
        <PrintButtonText>Imprimir cierre</PrintButtonText>
      </PrintButtonContainer>
    </StyledContainer>
  );
};

export default ModalConfirmClose;
